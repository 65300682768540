// custom Typefaces
import "./src/styles/common/fonts.scss";

// normalize CSS across browsers
import "./src/styles/common/reset.css";

// custom CSS styles
import "./src/styles/common/root.css";

// Global site structure formatting
//import "./src/styles/common/structure.css";

// global helper styles and mixins
//import "./src/styles/helpers/typography.scss" // Mixins only. can import in necessary file
// import "./src/styles/helpers/svg.css"
// import "./src/styles/helpers/button.scss"
// import "./src/styles/helpers/link.scss"

const onRouteUpdate = ({ location, prevLocation }) => {
  if (typeof window !== 'undefined') {
    setTimeout(() => window.scrollTo(0, 0), 20)
  }
}

const shouldUpdateScroll = ({ routerProps }) => {
  // const { disableScrollUpdate } = routerProps.location.state
  return true
}

export { onRouteUpdate, shouldUpdateScroll }